var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.configCopy)?_c('centered-column-layout',{staticClass:"feedback-settings"},[_c('highlight-anchor',{staticClass:"page-header-anchor-top-position",attrs:{"has-anchor-icon":"","anchor-key":"settings"}},[_c('a-page-header',{staticClass:"p-0 my-5"},[_c('field-title',{attrs:{"slot":"title","title-key":"feedback_settings","translation-params":[_vm.$store.getters.activeBoard.title]},slot:"title"}),_c('a-button',{staticClass:"btn-success",attrs:{"slot":"extra"},on:{"click":_vm.saveChannelConfig},slot:"extra"},[_vm._v(" "+_vm._s(_vm.$t('save_active_channel_config'))+" ")])],1)],1),_c('a-card',{staticClass:"feedback-settings__body"},[(_vm.pmConfig.feedback_group_type === _vm.FeedbackGroupType.Forum)?[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.configCopy,
            'key': 'allow_answer_without_reply',
            'hasAnchor': true,
          },
        }}}),_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.configCopy,
            'key': 'use_forum_status',
            'hasAnchor': true,
          },
        }}}),_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.configCopy,
            'key': 'use_admin_tags',
            'hasAnchor': true,
          },
        }}}),_c('a-divider')]:_vm._e(),_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.configCopy,
          'key': 'auto_reply_notify',
          'hasAnchor': true,
        },
      }}})],2),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('protection_section_title')}},[_c('a-alert',{attrs:{"show-icon":"","message":_vm.$t('protection_section_alert_message')}}),_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.configCopy.protection,
          'key': 'count',
          'prefix': 'protection_',
          'min': 0,
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: 5,
            hasFieldButton: true,
            disabledValue: 0
          }
        }
      }}}),(_vm.configCopy.protection.count > 0)?_c('nested-content',[_c('time-unit-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.configCopy.protection,
            'key': 'interval',
            'prefix': 'protection_',
            'min': 1,
          },
        }}}),_c('message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': this,
            'key': 'protectionMessage',
            'targetToUpload': {
              'target': 'target',
              'target_id': _vm.$store.getters.activeBoard.board
            },
            'hasMedia': true,
            'base-api-url': 'api.presscode.app/cabinet',
            'hasAnchor': true,
            'availableButtonsTypes': _vm.newPmButtons,
            'settingsStructure': {
              'disable_link_preview': false,
              'disable_notify': false,
              'pin': false,
              'remove_previous': true,
              'protect_content': false,
            }
          },
        }}})],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }