export enum BindTargetByCodeTypes {
    BindFeedback = 'BindFeedback',
    Status = 'Status',
}

export type BindTargetByCodeStatus = {
    type: BindTargetByCodeTypes.Status
    board_key:string
    code:string
}

export type BindTargetByCodeBind = {
    type: BindTargetByCodeTypes.BindFeedback
    board_key:string
}

export enum BindTargetStatus {
    Pending = 'pending',
    Success = 'success'
}

export type BindTargetByCode = BindTargetByCodeStatus | BindTargetByCodeBind