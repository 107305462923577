
























































































































































import { InputSetups } from '@/mixins/input-setups'
import { FeedbackGroupType, BoardPmConfig } from '@/includes/types/PmConfig.types'
import SaveChannelConfig from '@/components/SaveChannelConfig.vue'

import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'
import FieldTitle from 'piramis-base-components/src/components/Pi/components/FieldTitle.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'
import MessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMessageEditorWithMediaInput/MessageEditorWithMediaInput.vue'
import MessageEditorWithMediaData
  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { instanceToPlain, plainToInstance } from 'class-transformer'

@Component({
  components: {
    SaveChannelConfig,
    CenteredColumnLayout,
    FieldTitle,
    HighlightAnchor,
    MessageEditorWithMediaInput,
  },
  data() {
    return {
      FeedbackGroupType
    }
  }
})
export default class FeedbackSettings extends Mixins(UseFields, InputSetups) {
  configCopy: BoardPmConfig | null = null

  get protectionMessage() {
    return plainToInstance(MessageEditorWithMediaData, this.configCopy?.protection.message)
  }

  set protectionMessage(value: any) {
    this.configCopy!.protection.message = instanceToPlain(value) as BoardPmConfig['protection']['message']
  }

  get pmConfig() {
    return this.$store.state.boardsState.activeBoard?.config.pm_config
  }

  initConfigCopy() {
    this.configCopy = cloneDeep(this.pmConfig)
  }

  saveChannelConfig() {
    this.$store.commit('set_private_message_settings', this.configCopy)

    this.$store.dispatch('savePostBoardConfig')
      .then(res => {
        if (res) {
          this.initConfigCopy()
        }
      })
  }

  created() {
    if (this.$store.getters.isBoardSet) {
      this.initConfigCopy()
    }
  }
}
