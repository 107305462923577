























































































import { InputSetups } from '@/mixins/input-setups'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import { UserActivationActionType, UserAutoReplyAction } from '@/includes/types/PmConfig.types'
import PlaceholdersMixin from '@/mixins/PlaceholdersMixin'
import RunFlowButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/RunFlowButton'

import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import AutoReplyActions from 'piramis-base-components/src/components/BotFeedback/AutoReplyActions/AutoReplyActions.vue'
import {
  AutoReplyAction,
} from 'piramis-base-components/src/components/BotFeedback/AutoReplyActions/types'
import { ButtonType } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types'
import TextButton from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Text'
import { ButtonStruct } from "piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons";
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'
import LinkButtonBuilder
  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Link'
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    CenteredColumnLayout,
    AutoReplyActions,
    ConfigField,
    HighlightAnchor,
    MultiMessageEditorWithMediaInput
  },
  data() {
    return {
      EditorButtonsHelpView,
      TextButton,
    }
  }
})
export default class AutoReplyActionsPage extends Mixins<ModelSetter, InputSetups, PlaceholdersMixin>(ModelSetter, InputSetups, PlaceholdersMixin) {
  actionsBackup: Array<UserAutoReplyAction> | null = null

  alert = false

  get limitTypes() {
    if (this.$store.state.boardsState.activeBoard?.license_expired) {
      return [ UserActivationActionType.Flow ]
    }
  }

  get board() {
    return this.$store.state.boardsState.activeBoard
  }

  get autoReplyActionEditorButtons() {
    return [
      new LinkButtonBuilder(this.$i18n),
      new RunFlowButtonBuilder(this.$i18n, this.resolveFlowSelectOption),
    ]
  }

  @Watch('buttons')
  buttonsWatcher(buttons: Array<Array<ButtonType & { text: string }>>): void {
    const buttonsText: Array<string> = []
    buttons.forEach((row) => {
      row.forEach((button) => {
        buttonsText.push(button.text)
      })
    })

    const conditions: Array<string> = []

    if (this.board) {
      this.board.config.pm_config.user_auto_reply_actions!.forEach((action) => {
        action.conditions.forEach((group) => {
          group.forEach((condition) => {
            conditions.push(...condition.values)
          })
        })
      })
    }

    let i = 0
    let alert = false

    while (i < buttonsText.length && !alert) {
      alert = !conditions.some((condition) => condition.includes(buttonsText[i].trim()))
      i++
    }

    this.alert = alert
  }

  get buttons(): Array<Array<ButtonStruct>> {
    if (this.board) {
      const initialMessage = this.board.config.pm_config.initial_message
      if (initialMessage && initialMessage[0]) {
        return initialMessage[0].buttons
      }
    }
    return []
  }

  gotoFlowsList():void {
    this.$router.push({
      name: "Flows_List"
    })
  }

  saveConfig(): void {
    this.$store.dispatch('savePostBoardConfig')
  }

  addAction(command: AutoReplyAction): void {
    this.$store.commit('add_reply_action', command)
  }

  removeAction(guid: string) {
    this.$store.commit('remove_reply_action', guid)
    const isExist = this.actionsBackup!.find((a) => a.guid === guid)

    if (isExist) {
      return this.$store.dispatch('savePostBoardConfig')
        .then(() => {
          this.setBackup()

          return guid
        })
    }

    return Promise.resolve()
  }

  saveAction(action: AutoReplyAction): Promise<void> {
    this.$store.commit('update_reply_action', action)
    return this.$store.dispatch('savePostBoardConfig')
      .then(() => this.setBackup())
  }

  setBackup(): void {
    this.actionsBackup = cloneDeep(this.board!.config.pm_config.user_auto_reply_actions)
  }

  created(): void {
    this.setBackup()

    this.buttonsWatcher(this.buttons)
  }
}
