



















import { InputSetups } from '@/mixins/input-setups'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { BindTargetByCodeTypes, BindTargetStatus } from '@/includes/types/BindTargetByCode.types'
import BindTargetService from '@/includes/services/BindTargetService'
import { BoardPmConfig, UserActivationActionType } from '@/includes/types/PmConfig.types'
import PlaceholdersMixin from '@/mixins/PlaceholdersMixin'
import BotDefaultEditorMessage from '@/components/HelpMessages/editor/BotDefaultEditorMessage.vue'
import { setPostBoardConfig } from '@/includes/logic/Bot/BotConfig'
import { Board } from '@/includes/types/Board.types'

import PrivateMessageSettings from 'piramis-base-components/src/components/BotFeedback/PrivateMessageSettings/PrivateMessageSettings.vue'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  data() {
    return {
      BotDefaultEditorMessage,
    }
  },
  components: {
    PrivateMessageSettings,
    CenteredColumnLayout,
  }
})
export default class PrivateMessagesSettingsPage extends Mixins<InputSetups, PlaceholdersMixin>(InputSetups, PlaceholdersMixin) {
  get pmConfig(): BoardPmConfig {
    return this.$store.state.boardsState.activeBoard!.config.pm_config
  }

  get limitTypes() {
    if (this.$store.state.boardsState.activeBoard?.license_expired) {
      return [ UserActivationActionType.Flow ]
    }
  }

  bindTargetByCode(args: { type: BindTargetByCodeTypes.BindFeedback } | { type: BindTargetByCodeTypes.Status, code: string }): Promise<any> {
    return new Promise(resolve => {
      BindTargetService.bindTargetByCode('tg', Object.assign(args, { board_key: this.$store.state.boardsState.activeBoard!.board }))
        .then((res) => {
          resolve(res)
          if (res.status === BindTargetStatus.Success) {
            this.$store.dispatch('getBoardConfig', this.$store.state.boardsState.activeBoard!.board)
          }
        })
    })
  }

  saveConfig(pmConfig: BoardPmConfig) {
    const { activeBoard }: { activeBoard: Board } = this.$store.state.boardsState
    const { board, lang, timezone, config } = activeBoard

    return setPostBoardConfig(
      board, {
        config: { ...config, pm_config: pmConfig },
        lang,
        timezone,
      }
    )
      .then(res => res)
  }

  gotoFlowsList():void {
    this.$router.push({
      name: "Flows_List"
    })
  }

  get adminList():Array<SelectOptionData> {
    return this.$store.state.boardsState.activeBoard?.admins
      .slice()
      .filter(a => a.limits === null)
      .map(a => ({
        value: a.user_id,
        label: `${ a.name } ${ a.username ? '(@' + a.username + ')' : '' }`
      }))
  }
}
