





















































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'

import AutoReplyActions from 'piramis-base-components/src/components/BotFeedback/AutoReplyActions/AutoReplyActions.vue'
import { AutoReplyAction } from 'piramis-base-components/src/components/BotFeedback/AutoReplyActions/types'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'
import { atSignedLogin } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import Vue from 'vue'
import { Component, Emit } from 'vue-property-decorator'
import { mapActions } from 'vuex'

@Component({
  components: {
    AutoReplyActions,
    ConfigField,
    CenteredColumnLayout,
    HighlightAnchor
  },
  methods: {
    ...mapActions([ 'resolveFlowSelectOption' ])
  }
})
export default class PmCommandsPage extends Vue {
  resolveFlowSelectOption!: Promise<Array<SelectOption>>
  
  @Emit('goToBotFeedbackId')
  goToBotFeedbackId(): void {
    return
  }

  get bindFeedbackId(): string | null {
    const id = this.$store.state.boardsState.activeBoard!.config.pm_config.feedback_group_id

    if (id) {
      const admin = this.$store.state.boardsState.activeBoard!.admins.find((a) => a.user_id === id)

      if (admin) {
        return atSignedLogin(admin.username)
      }

      return id
    }

    return null
  }

  removeAction(guid: string): Promise<void> {
    this.$store.commit('remove_pm_command', guid)
    return this.$store.dispatch('savePostBoardConfig')
  }

  saveAction(action: AutoReplyAction): Promise<void> {
    this.$store.commit('update_pm_command', action)
    return this.$store.dispatch('savePostBoardConfig')
  }

  gotoFlowsList():void {
    this.$router.push({
      name: "Flows_List"
    })
  }
}
